<script setup lang="ts">
const dialogVisible = defineModel("dialogVisible", { type: Boolean });
const UseLocalStore = useLocalStore();
const { cookiesSettings } = storeToRefs(UseLocalStore);
const { t } = useI18n();
const onReject = () => {
  dialogVisible.value = false;
  cookiesSettings.value.settedUp = true;
  cookiesSettings.value.allCookies = false;
};
const onAccept = () => {
  dialogVisible.value = false;
  cookiesSettings.value.settedUp = true;
  cookiesSettings.value.allCookies = true;
};
</script>

<template>
  <el-card v-if="dialogVisible" class="cookie-alert-popup">
    <h3>{{ t("c-user-cookiealert-cookie-preference") }}</h3>
    <div>
      <p>
        {{ t("c-user-cookiealert-essential-cookies") }}
      </p>
      <p>
        {{ t("c-user-cookiealert-additional-cookies") }}
      </p>
      <p>
        {{ t("c-user-cookiealert-manage-cookies") }}
      </p>
    </div>
    <div class="text-center whitespace-nowrap">
      <el-button @click="onReject">{{
        t("c-user-cookiealert-reject-cookies")
      }}</el-button>
      <el-button @click="onAccept" type="primary">{{
        t("c-user-cookiealert-accept-continue")
      }}</el-button>
    </div>
  </el-card>
</template>

<style scoped lang="scss">
.cookie-alert-popup {
  width: 440px;
  max-width: 95%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999999;
  text-align: left;
  @media (max-width: 400px) {
    :deep(.el-card__body) {
      padding: 0.8rem;
    }
  }
}
</style>
